import { fromJS } from 'immutable';

const initialState = fromJS({
  reports: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'CLEAR_REPORTS': {
      return state.set('reports', fromJS([]));
    }

    case 'ADD_REPORTS': {
      const sortedReports = fromJS(action.reports).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });

      return state.set('reports', sortedReports);
    }

    case 'REMOVE_REPORT': {
      const removableReport = state.get('reports').findIndex(report => report.get('id') === fromJS(action.report));

      if (removableReport !== -1) {
        return state.set('reports', state.get('reports').delete(removableReport));
      }

      return state;
    }
  
    case 'ADD_REPORT': {
     const newReport = fromJS(action.report);

      if (state.get('reports').find(report => report.get('id') === newReport.get('id')) === undefined) {
        return state.set('reports', state.get('reports').push(newReport));
      }

      return state;
    }

    case 'CHANGE_REPORT': {
      const changingIndex = state.get('reports').findIndex(report => report.get('id') === fromJS(action.report.id));
      if (state.get('reports').get(changingIndex) === fromJS(action.report)) {
        return state;
      }
      const newReports = state.get('reports').set(changingIndex, fromJS(action.report));
      return state.set('reports', newReports);
    }

    default: {
      return state;
    }
  }
};