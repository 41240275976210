import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import LoginReducer from '../../../../src/login/Reducer.js';
import MenuReducer from '../../../../src/menu/Reducer.js';
import MessageReducer from '../../../../src/message/Reducer.js';
import NoticeReducer from '../../../../src/notice/Reducer.js';
import ConfirmReducer from '../../../../src/confirm/Reducer.js';
import ContractSelectReducer from '../../../../src/contractSelect/Reducer.js';
import ConstructionSiteSelectReducer from '../../../../src/constructionSiteSelect/Reducer.js';
import TimeRangeReducer from '../../../../src/timeRange/Reducer.js';
import Login from '../../../../src/login/Login.js';
import Logout from '../../../../src/login/Logout.js';
import RoadHandReducer from '../roadHand/Reducer';

import App from './Application.js';
import RoadHand from '../roadHand/RoadHand.js';

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    roadHand: RoadHandReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    timeRange: TimeRangeReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const AppWithLocation = () => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes>
        <Route path='/login' element={<Login store={store} />}/>
        <Route path='/logout' element={<Logout store={store} />}/>
        <Route path='/' element={<RoadHand store={store} />}/>
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;