import React from 'react';
import { connect } from 'react-redux';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { changeContructionSite } from '../../../../src/constructionSiteSelect/Actions';
import { clearReports, addReports, removeReport, addReport } from './Actions';
import { fetch, fetchFile, stateValueParser, b64toBlob, paddedNumber, timer } from '../utils';
import './RoadHand.css';


const NewReport = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.toggle} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        { !props.loading ?
          <div>
            <h3>Uusi raportti</h3>
            <label htmlFor='comment'>
              Kommentti
            </label>
            <input id='comment' type='text'
                  onChange={props.onChange.bind(this, 'comment', 'string', '')}
                  value={props.comment || ''} />
            <label>
              Tiedosto
              <input type='file' accept={''}
                    onChange={props.onChange.bind(this, 'file', 'file', null)} />
            </label>
            <div className="row">
              <div className="column">
                <label>
                  Tutka raportti
                  <input type='checkbox'
                        onClick={props.onChange.bind(this, 'radarReport', 'boolean', false)}
                        defaultChecked={props.radarReport} />
                </label>
              </div>
              <div className="column">
                <label>
                  PTM raportti
                  <input type='checkbox'
                        onClick={props.onChange.bind(this, 'ptmReport', 'boolean', false)}
                        defaultChecked={props.ptmReport} />
                </label>
              </div>
            </div>
            <div className="row inputs">
              <div className="column">
                <button onClick={props.createNewReport}>
                  Lisää
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.toggle}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}


const Loader = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div className='modal'>
      <div id='modal-container'>
          <div className='loader' />
      </div>
    </div>
  )
}


class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingNewReport: false,
      comment: '',
      file: [],
      radarReport: false,
      ptmReport: false,
      deletingReportId: null,
      loading: false,
      loadingInput: false,
      showLoader: false
    };

    this.changeState = this.changeState.bind(this);
    this.toggleNewReport = this.toggleNewReport.bind(this);
    this.clearNewReport = this.clearNewReport.bind(this);
    this.createNewReport = this.createNewReport.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
  }

  componentDidUpdate(lastProps, lastState) {
    if ((this.props.selectedConstructionSite === lastProps.selectedConstructionSite &&
        this.props.selectedContract === lastProps.selectedContract) ||
        this.props.selectedYear !== lastProps.selectedYear) {
      return;
    }

    this.props.clearReports();

    if (this.props.selectedConstructionSite != null) {
      this.getReports(this.props.selectedContract, this.props.selectedConstructionSite.get('id'));
    }
    else if (this.props.selectedContract != null) {
      this.getReports(this.props.selectedContract);
    }
  }

  async getReports(contractId, siteId) {
    this.setState({ loading: true });

    let url = '/roadhandreport';

    if (siteId) {
      url = url + '?site=' + siteId;
    }
    else {
      url = url + '?contract=' + contractId;
    }

    fetch(url).then(async data => {
      this.props.addReports(data);
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    }).finally(error => {
      this.setState({ loading: false });
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    if (type === 'file') {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({
            [propertyName]: {
                              name: files[i].name,
                              file: reader.result
                            }
          });
        }

        reader.readAsDataURL(files[i]);
      }

      return;
    }
    else {
      const value = stateValueParser(event, type, defaultValue);

      if (value == null) {
        return;
      }

      this.setState({ [propertyName]: value });
    }
  }

  toggleNewReport() {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    this.setState({ creatingNewReport: !this.state.creatingNewReport });
  }

  clearNewReport() {
    this.toggleNewReport();

    this.setState({
      comment: '',
      file: [],
      radarReport: false
    });
  }

  async createNewReport() {
    const comment = this.state.comment;

    if (comment === '') {
      this.props.showNotice('Kommenttia ei ole annettu', 'Warning');
      return;
    }

    this.setState({ loadingInput: true });

    const siteId = this.props.selectedConstructionSite.get('id');
    const date = new Date();
    const file = this.state.file;

    const report = {
      construction_site_id: siteId,
      time: date.toISOString(),
      comment: comment, 
      file: file.name
    };

    let newReport;

    try {
      newReport = await fetch('/roadhandreport', 'POST', report);
    } catch(error) {
      this.props.showNotice('Lisääminen epäonnistui (1)', 'Error');
      this.setState({ loadingInput: false });
      return;
    }

    const filePath = 'roadhand/' + siteId + '/' + newReport.id + '/';

    if (file.file != null) {
      try {
        const fileData = file.file;
        const block = fileData.split(";");
        const contentType = block[0].split(":")[1];
        const realData = block[1].split(",")[1];
        const blob = b64toBlob(realData, contentType);
        const formData = new FormData();
        formData.append('file', blob, file.name);
        await fetchFile(formData, 'POST', filePath);
      } catch(error) {
        this.props.showNotice('Lisääminen epäonnistui (2)', 'Error');
      }
    }

    if (this.state.radarReport || this.state.ptmReport) {
      const reportedText = 'Raportoitu';

      let site = {};

      if (this.state.radarReport) {
        site.radar_status = reportedText;
      }

      if (this.state.ptmReport) {
        site.ptm_status = reportedText;
      }
  
      try {
        const data = await fetch('/constructionsites/' + this.props.selectedConstructionSite.get('id'),
                                 'PATCH', site);
        this.sendEmail(filePath + file.name);
        this.props.changeContructionSite(data);
      } catch(error) {
        
      }
    }

    this.props.addReport(newReport);

    this.props.showNotice('Lisätty', 'Ok');

    this.setState({ loadingInput: false });

    this.clearNewReport();
  }

  async sendEmail(filePath) {
    try {
      let users = await fetch('/users/all', 'GET');
      let usersOfContract = [];

      for (let user of users) {
        for (let permission of user.contractPermissions) {
          if (permission.contract_id === this.props.selectedContract) {
            usersOfContract.push(user);
          }
        }
      }

      const selectedContract = this.props.contracts.find(contract => contract.get('id') === this.props.selectedContract);

      const reporterEmail = this.props.user.get('email');
      const subject = 'Tierenki - ' + selectedContract.get('name') + ' on lisätty raportti';

      const link = 'https://nevia-app.com/file/' + filePath.replaceAll('/', '|');

      const message = '<h3>Kohteen ' + this.props.selectedConstructionSite.get('name') +
       ' raportin löydät <a href="' + link + '/">täältä</a></h3>';

      for (let user of usersOfContract) {
        await fetch('email/send/' + user.email + '/' + subject, 'POST', message);
      }

      await fetch('email/send/' + reporterEmail + '/' + subject, 'POST', message);
    } catch(error) {
      console.log(error)
      this.props.showNotice('Sähköpostien lähetys epäonnistui', 'Error');
    }
  }

  aboutToDeleteReport(report) {
    this.setState({ deletingReport: report });

    this.props.showConfirm('Poistetaanko raportti: ' + report.get('comment') + ' ' +
      (report.get('file') || '') + '?', this.deleteReport);
  }

  deleteReport() {
    timer(0).then(async () => {
      this.setState({ loading: true });

      try {
        const path = 'roadhand/' + this.state.deletingReport.get('construction_site_id') + '/' + 
                      this.state.deletingReport.get('id') + '/';
        try {
          await fetchFile(null, 'DELETE', path);
        } catch(err) {}

        await fetch('/roadhandreport/' + this.state.deletingReport.get('id'), 'DELETE');
        this.props.removeReport(this.state.deletingReport.get('id'));
        this.props.showNotice('Poistettu', 'Ok');
      } catch(e) {
        this.props.showNotice('Poisto epäonnistui', 'Error');
      }

      this.setState({ loading: false });
    })
  }

  async showReport(report) {
    if (report.get('file') == null) {
      this.props.showNotice('Ei tiedostoa', 'Warning');
      return;
    }

    this.setState({ showLoader: true });

    try {
      const path = 'roadhand/' + report.get('construction_site_id') + '/' + 
                    report.get('id') + '/' + report.get('file');

      const file = await fetchFile(null, 'GET', path);
      this.downloadFile(file, report.get('file'));
    } catch(error) {
      this.props.showNotice('Lataus epäonnistui', 'Error');
    }

    this.setState({ showLoader: false });
  }

  downloadFile(blob, name) {
    const blobUrl = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
  
    link.href = blobUrl;
    link.download = name;
  
    document.body.appendChild(link);
  
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );
  
    document.body.removeChild(link);
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    if (this.state.loading) {
      return <div className='loader' />
    }

    return (
      <div>
        { !this.props.orderer ?
          <button onClick={this.toggleNewReport}>
            Uusi raportti
          </button>
          :
          null
        }
        { this.props.reports.size !== 0 ?
          <div className="row">
            <div className="column short">
              <b>Kommentti</b>
            </div>
            <div className="column long">
              <b>Tiedosto</b> 
            </div>
            <div className="column short">
              <b>Aika</b>
            </div>
            { !this.props.orderer ?
              <div className="column short">
              </div>
              :
              null
            }
          </div>
          :
          <h3>Ei yhtään raporttia</h3>
        }
        {
          this.props.reports.map(report => {
            const date = new Date(report.get('time'));
            const dateText = date.getDate() + '.' + (date.getMonth() + 1) +
              '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) +
              ':' + paddedNumber(date.getMinutes());

            return (
              <div key={report.get('id')} className={"report"}
                  onClick={this.showReport.bind(this, report)}>
                <div className="row">
                  <div className="column short">
                    {report.get('comment')}
                  </div>
                  <div className="column long">
                    {report.get('file') || '-'}
                  </div>
                  <div className="column short">
                    {dateText}
                  </div>
                  { !this.props.orderer ?
                    <div className="column short">
                      <button className='button-outline' onClick={(e) => {
                        e.stopPropagation();
                        this.aboutToDeleteReport(report);
                      }}>
                        Poista
                      </button>
                    </div>
                    :
                    null
                 }
                </div>
              </div>
            );
          })
        }
        <NewReport show={this.state.creatingNewReport}
                   onChange={this.changeState}
                   comment={this.state.comment}
                   radarReport={this.state.radarReport}
                   ptmReport={this.state.ptmReport}
                   createNewReport={this.createNewReport}
                   toggle={this.toggleNewReport}
                   loading={this.state.loadingInput} />
        <Loader show={this.state.showLoader} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  contracts: state.contractSelect.get('contracts'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  reports: state.roadHand.get('reports'),
  user: state.login.get('user'),
}), { clearReports, addReports, removeReport, addReport,
      showNotice, showConfirm, showMessage,
      changeContructionSite })(Reports);
