export const clearReports = () => ({
  type: 'CLEAR_REPORTS'
});


export const addReports = reports => ({
  type: 'ADD_REPORTS',
  reports
});


export const removeReport = report => ({
  type: 'REMOVE_REPORT',
  report
});


export const addReport = report => ({
  type: 'ADD_REPORT',
  report
});


export const changeReport = report => ({
  type: 'CHANGE_REPORT',
  report
});
