import React from 'react';
import { connect } from 'react-redux';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { addContructionSite, removeContructionSite, setContructionSites, changeContructionSite } from '../../../../src/constructionSiteSelect/Actions'
import { clearReports, addReports, removeReport, addReport, changeReport } from './Actions';
import {
  fetch, stateValueParser, b64toBlob, getRoadCoordinates, toWGS84,
  calculateDistance
} from '../utils';
import * as XLSX from 'xlsx';
import './RoadHand.css';
import { fromJS } from 'immutable';


const STATUSES = ['', 'Mitattavissa', 'Mitattu', 'Raportoitu']


const Fields = props => {
  return (
    <div >
      <div className="row">
        <div className="column">
          <label htmlFor='customId'>
            Tunnus
          </label>
          <input id='customId' type='text'
            onChange={props.onChange.bind(this, 'customId', 'string', '')}
            value={props.customId || ''} />
        </div>
        <div className="column">
          <label htmlFor='name'>
            Nimi
          </label>
          <input id='name' type='text'
            onChange={props.onChange.bind(this, 'name', 'string', '')}
            value={props.name || ''} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor='road'>
            Tie
          </label>
          <input id='road' type='number'
            onChange={props.onChange.bind(this, 'road', 'integer', '')}
            value={props.road || ''} />
        </div>
        <div className="column">
          <label htmlFor='startPart'>
            Aloitus osa
          </label>
          <input id='startPart' type='number'
            onChange={props.onChange.bind(this, 'startPart', 'integer', '')}
            value={props.startPart || ''} />
        </div>
        <div className="column">
          <label htmlFor='startDistance'>
            Aloitus paalu
          </label>
          <input id='startDistance' type='number'
            onChange={props.onChange.bind(this, 'startDistance', 'integer', '')}
            value={props.startDistance !== '' ? props.startDistance : ''} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor='endPart'>
            Lopetus osa
          </label>
          <input id='endPart' type='number'
            onChange={props.onChange.bind(this, 'endPart', 'integer', '')}
            value={props.endPart || ''} />
        </div>
        <div className="column">
          <label htmlFor='endDistance'>
            Lopetus paalu
          </label>
          <input id='endDistance' type='number'
            onChange={props.onChange.bind(this, 'endDistance', 'integer', '')}
            value={props.endDistance !== '' ? props.endDistance : ''} />
        </div>
        <div className="column">
          <label htmlFor='length'>
            Pituus
          </label>
          <input id='length' type='number'
            onChange={props.onChange.bind(this, 'length', 'integer', '')}
            value={props.length || ''} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor='lane'>
            Kaista
          </label>
          <input id='lane' type='number'
            onChange={props.onChange.bind(this, 'lane', 'integer', '')}
            value={props.lane !== '' ? props.lane : ''} />
        </div>
        <div className="column">
          <label htmlFor='kvl'>
            KVL
          </label>
          <input id='kvl' type='number'
            onChange={props.onChange.bind(this, 'kvl', 'integer', '')}
            value={props.kvl || ''} />
        </div>
        <div className="column">
          <label htmlFor='massType'>
            Massatyyppi
          </label>
          <input id='massType' type='text'
            onChange={props.onChange.bind(this, 'massType', 'string', '')}
            value={props.massType || ''} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor='flatness'>
            Paksuus (kg/m²)
          </label>
          <input id='flatness' type='number'
            onChange={props.onChange.bind(this, 'flatness', 'integer', '')}
            value={props.flatness || ''} />
        </div>
        <div className="column">
          <label htmlFor='operationType'>
            Menetelmä
          </label>
          <input id='operationType' type='text'
            onChange={props.onChange.bind(this, 'operationType', 'string', '')}
            value={props.operationType || ''} />
        </div>
        <div className="column">
          <label htmlFor='quality'>
            Laatu
          </label>
          <input id='quality' type='text'
            onChange={props.onChange.bind(this, 'quality', 'string', '')}
            value={props.quality || ''} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label>
            Tutka status
            <select onChange={props.onChange.bind(this, 'radarStatus', 'string', null)}
              value={props.radarStatus || ''}>
              {
                STATUSES.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))
              }
            </select>
          </label>
        </div>
        <div className="column">
          <label>
            PTM status
            <select onChange={props.onChange.bind(this, 'ptmStatus', 'string', null)}
              value={props.ptmStatus || ''}>
              {
                STATUSES.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))
              }
            </select>
          </label>
        </div>
        <div className="column">
          <label htmlFor='comments'>
            Kommentit
          </label>
          <input id='comments' type='text'
            onChange={props.onChange.bind(this, 'comments', 'string', '')}
            value={props.comments || ''} />
        </div>
      </div>
    </div>
  )
}

const NewSite = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.toggle} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        {!props.loading ?
          <div>
            <h3>Uusi kohde</h3>
            <Fields onChange={props.onChange}
              customId={props.customId}
              name={props.name}
              road={props.road}
              startPart={props.startPart}
              endPart={props.endPart}
              startDistance={props.startDistance}
              endDistance={props.endDistance}
              length={props.length}
              lane={props.lane}
              kvl={props.kvl}
              massType={props.massType}
              massTypes={props.massTypes}
              flatness={props.flatness}
              operationType={props.operationType}
              quality={props.quality}
              radarStatus={props.radarStatus}
              ptmStatus={props.ptmStatus}
              comments={props.comments} />
            <div className="row inputs">
              <div className="column">
                <button onClick={props.createNewSite}>
                  Lisää
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.toggle}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}

const EditSite = props => {
  if (props.editingSite == null) {
    return null;
  }

  return (
    <div onClick={props.clearEditSite} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        {!props.loading ?
          <div>
            <h3>Muokkaa</h3>
            <h3>
              {props.editingSite.get('contract').get('name')}
            </h3>
            <h3>
              {(props.editingSite.get('custom_id') ? (props.editingSite.get('custom_id') + ' ') : '')
                + props.editingSite.get('name')}
            </h3>
            <button onClick={props.openGoogleMapDirections}>
              Näytä reittiohjeet
            </button>
            <Fields onChange={props.onChange}
              customId={props.customId}
              name={props.name}
              road={props.road}
              startPart={props.startPart}
              endPart={props.endPart}
              startDistance={props.startDistance}
              endDistance={props.endDistance}
              length={props.length}
              lane={props.lane}
              kvl={props.kvl}
              massType={props.massType}
              massTypes={props.massTypes}
              flatness={props.flatness}
              operationType={props.operationType}
              quality={props.quality}
              radarStatus={props.radarStatus}
              ptmStatus={props.ptmStatus}
              comments={props.comments} />
            <div className="row inputs">
              <div className="column">
                <button onClick={props.editSite}>
                  Muokkaa
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.clearEditSite}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}

const InputCSVFile = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.toggle} className='modal'>
      <div id='modal-container' onClick={e => e.stopPropagation()}>
        <h3>Uusi Kohde CSV-tiedostosta</h3>
        {!props.loading ?
          <div>
            <label>
              Tiedosto
              <input type='file' accept={'.csv'}
                onChange={props.onChange.bind(this, 'CVSFile', 'file', null)} />
            </label>
            <div className="row inputs">
              <div className="column">
                <button onClick={props.input}>
                  Syötä
                </button>
              </div>
              <div className="column">
                <button className='button-outline' onClick={props.toggle}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
          :
          <div className='loader' />
        }
      </div>
    </div>
  )
}

class ConstructionSites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingNewSite: false,
      customId: null,
      name: '',
      road: null,
      startPart: null,
      endPart: null,
      startDistance: null,
      endDistance: null,
      length: null,
      lane: '',
      kvl: '',
      masstype: '',
      flatness: null,
      operationType: '',
      quality: '',
      radarStatus: '',
      ptmStatus: '',
      comments: '',
      deletingSiteId: null,
      loading: false,
      loadingInput: false,
      editingSite: null,
      CVSFile: [],
      radarStatusFilter: '',
      ptmStatusFilter: '',
      sites: fromJS([])
    };

    this.changeState = this.changeState.bind(this);
    this.toggleNewSite = this.toggleNewSite.bind(this);
    this.clearNewSite = this.clearNewSite.bind(this);
    this.createNewSite = this.createNewSite.bind(this);
    this.deleteSite = this.deleteSite.bind(this);
    this.clearEditSite = this.clearEditSite.bind(this);
    this.editSite = this.editSite.bind(this);
    this.toggleInputCSVFile = this.toggleInputCSVFile.bind(this);
    this.createSiteFromCSVFile = this.createSiteFromCSVFile.bind(this);
    this.openGoogleMapDirections = this.openGoogleMapDirections.bind(this);
  }

  componentDidMount () {
    this.getSites(this.props.selectedContract);
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.props.constructionSites !== lastProps.constructionSites ||
      this.state.radarStatusFilter !== lastState.radarStatusFilter ||
      this.state.ptmStatusFilter !== lastState.ptmStatusFilter) {
      this.filterSites(this.props.constructionSites);
    }

    if (this.props.selectedContract === lastProps.selectedContract) return;

    this.getSites(this.props.selectedContract);
  }

  changeState (propertyName, type, defaultValue, event) {
    if (type === 'file') {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({
            [propertyName]: {
              name: files[i].name,
              file: reader.result
            }
          });
        }

        reader.readAsDataURL(files[i]);
      }

      return;
    }
    else {
      const value = stateValueParser(event, type, defaultValue);

      if (value == null) {
        return;
      }

      this.setState({ [propertyName]: value });
    }
  }

  async getSites (contract) {
    this.setState({ loading: true });

    let url;

    if (contract != null) {
      url = '/constructionsites?contract=' + contract;
    }
    else {
      url = '/constructionsites';
    }

    const data = await fetch(url);

    this.setState({ loading: false });
    this.props.setContructionSites(data);
  }

  async filterSites (sites) {
    let filteredSites = sites;

    if (this.state.radarStatusFilter !== '') {
      filteredSites = filteredSites.filter(site => site.get('radar_status') === this.state.radarStatusFilter);
    }

    if (this.state.ptmStatusFilter !== '') {
      filteredSites = filteredSites.filter(site => site.get('ptm_status') === this.state.ptmStatusFilter);
    }

    this.setState({ sites: filteredSites });
  }

  toggleNewSite () {
    if (this.props.selectedContract == null) {
      this.props.showNotice('Urakkaa ei ole valittu', 'Warning')
      return;
    }
    this.setState({ creatingNewSite: !this.state.creatingNewSite });
  }

  clearFields () {
    this.setState({
      customId: null,
      name: '',
      road: null,
      startPart: null,
      endPart: null,
      startDistance: null,
      endDistance: null,
      length: null,
      lane: '',
      kvl: '',
      massType: null,
      flatness: null,
      operationType: '',
      quality: '',
      radarStatus: '',
      ptmStatus: '',
      comments: '',
    });
  }

  clearNewSite () {
    this.toggleNewSite();
    this.clearFields();
  }

  async createNewSite () {
    if (this.state.name === '') {
      this.props.showNotice('Nimeä ei ole annettu', 'Warning');
      return;
    }

    this.setState({ loadingInput: true });

    const site = {
      contract_id: this.props.selectedContract,
      custom_id: this.state.customId,
      name: this.state.name,
      road_number: this.state.road,
      start_part: this.state.startPart,
      end_part: this.state.endPart,
      start_distance: this.state.startDistance,
      end_distance: this.state.endDistance,
      length: this.state.length,
      lane: this.state.lane,
      kvl: this.state.kvl,
      mass_type: this.state.massType,
      flatness: this.state.flatness,
      operation_type: this.state.operationType,
      quality: this.state.quality,
      radar_status: this.state.radarStatus,
      ptm_status: this.state.ptmStatus,
      comments: this.state.comments
    };

    try {
      const data = await fetch('/constructionsites', 'POST', site);
      this.props.addContructionSite(data);
      this.props.showNotice('Lisätty', 'Ok');
      this.clearNewSite();
    } catch (error) {
      this.props.showNotice('Lisääminen epäonnistui', 'Error');
    }

    this.setState({ loadingInput: false });
  }

  aboutToDeleteSite (site) {
    this.setState({ deletingSiteId: site.get('id') });

    this.props.showConfirm('Poistetaanko raportti: ' +
      (site.get('custom_id') ? site.get('custom_id') + ' ' : '') +
      site.get('name') + '?', this.deleteSite);
  }

  async deleteSite () {
    this.setState({ loading: true });
    try {
      const data = await fetch('/constructionsites/' + this.state.deletingSiteId, 'DELETE');

      if (data !== undefined) {
        this.props.removeContructionSite(this.state.deletingSiteId);
        this.props.showNotice('Poistettu', 'Ok');
      }
      else {
        this.props.showNotice('Poisto epäonnistui, koska kohteella on joitain liitoksia', 'Error');
      }

    } catch (error) {
      if (error.message === '409') {
        this.props.showMessage('Virhe', 'Kohteeseen on lisätty asioita (esim. raportti)', 'Error');
      }
      else {
        this.props.showNotice('Poisto epäonnistui', 'Error');
      }
    }
    this.setState({ loading: false });
  }

  clearEditSite () {
    this.setState({ editingSite: null });
    this.clearFields();
  }

  async showSite (site) {
    if (this.props.orderer) {
      return;
    }

    this.setState({
      editingSite: site,
      customId: site.get('custom_id') || '',
      name: site.get('name') || '',
      road: site.get('road_number') || '',
      startPart: site.get('start_part') || '',
      endPart: site.get('end_part') || '',
      startDistance: site.get('start_distance') != null ? site.get('start_distance') : '',
      endDistance: site.get('end_distance') != null ? site.get('end_distance') : '',
      length: site.get('length') || '',
      lane: site.get('lane') != null ? site.get('lane') : '',
      kvl: site.get('kvl') || '',
      massType: site.get('mass_type') || '',
      flatness: site.get('flatness') || '',
      operationType: site.get('operation_type') || '',
      quality: site.get('quality') || '',
      radarStatus: site.get('radar_status') || '',
      ptmStatus: site.get('ptm_status') || '',
      comments: site.get('comments') || ''
    });
  }

  async editSite () {
    if (this.state.name === '') {
      this.props.showNotice('Nimeä ei ole annettu', 'Warning');
      return;
    }

    this.setState({ loadingInput: true });

    const site = {
      custom_id: this.state.customId,
      name: this.state.name,
      road_number: this.state.road,
      start_part: this.state.startPart,
      end_part: this.state.endPart,
      start_distance: this.state.startDistance,
      end_distance: this.state.endDistance,
      length: this.state.length,
      lane: this.state.lane,
      kvl: this.state.kvl,
      mass_type: this.state.massType,
      flatness: this.state.flatness,
      operation_type: this.state.operationType,
      quality: this.state.quality,
      radar_status: this.state.radarStatus,
      ptm_status: this.state.ptmStatus,
      comments: this.state.comments
    };

    try {
      const data = await fetch('/constructionsites/' + this.state.editingSite.get('id'),
        'PATCH', site);
      this.props.changeContructionSite(data);
      this.props.showNotice('Muokattu', 'Ok');
      this.clearEditSite();
    } catch (error) {
      this.props.showNotice('Muokkaus epäonnistui', 'Error');
    }

    this.setState({ loadingInput: false });
  }

  toggleInputCSVFile () {
    if (this.props.selectedContract == null) {
      this.props.showNotice('Urakkaa ei ole valittu', 'Warning')
      return;
    }

    this.setState({
      inputingCSVFile: !this.state.inputingCSVFile,
      CVSFile: []
    });
  }

  createSiteFromCSVFile () {
    const file = this.state.CVSFile;

    if (file.file == null) {
      this.props.showNotice('Tiedostoa ei ole annettu', 'Warning');
      return;
    }

    const reader = new FileReader();

    reader.onload = async function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets.Sheet1;

      console.log(sheet)

      this.setState({ loadingInput: true });

      let row = 2;

      while (true) {
        let customId;
        let name
        let road;
        let lane;
        let startPart;
        let startDistance;
        let endPart;
        let endDistance;
        let length;
        let massType;
        let flatness;
        let operationType;
        let quality;
        let kvl;

        try {
          customId = sheet['A' + row].v;
          name = sheet['B' + row].v;
          road = sheet['C' + row].v;
          lane = sheet['D' + row].v;
          startPart = sheet['E' + row].v;
          startDistance = sheet['F' + row].v;
          endPart = sheet['G' + row].v;
          endDistance = sheet['H' + row].v;
          length = sheet['I' + row].v;
          massType = sheet['J' + row].v;
          flatness = sheet['K' + row].v;
          operationType = sheet['L' + row].v;
          quality = sheet['M' + row].v;
          kvl = sheet['N' + row].v;
        } catch (error) {
          break;
        }

        road = typeof road === 'string' ? road.replaceAll(' ', '') : road;
        lane = typeof lane === 'string' ? lane.replaceAll(' ', '') : lane;
        startPart = typeof startPart === 'string' ? startPart.replaceAll(' ', '') : startPart;
        startDistance = typeof startDistance === 'string' ? startDistance.replaceAll(' ', '') : startDistance;
        endPart = typeof endPart === 'string' ? endPart.replaceAll(' ', '') : endPart;
        endDistance = typeof endDistance === 'string' ? endDistance.replaceAll(' ', '') : endDistance;
        length = typeof length === 'string' ? length.replaceAll(' ', '') : length;
        kvl = typeof kvl === 'string' ? kvl.replaceAll(' ', '') : kvl;

        const site = {
          contract_id: this.props.selectedContract,
          custom_id: customId,
          name: name,
          road_number: road,
          start_part: startPart,
          end_part: endPart,
          start_distance: startDistance,
          end_distance: endDistance,
          length: length,
          lane: lane,
          kvl: kvl,
          mass_type: massType,
          flatness: flatness,
          operation_type: operationType,
          quality: quality
        };


        try {
          const data = await fetch('/constructionsites', 'POST', site);
          this.props.addContructionSite(data);
          this.props.showNotice('Lisätty', 'Ok');
        } catch (error) {
          this.props.showNotice('Lisääminen epäonnistui', 'Error');
        }

        row++;
      }

      this.toggleInputCSVFile();
      this.setState({ loadingInput: false });
    }.bind(this);

    const fileData = file.file;
    const block = fileData.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const blob = b64toBlob(realData, contentType);
    reader.readAsArrayBuffer(blob);
  }

  openGoogleMapDirections (targetLatitude, targetLongitude) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      if (latitude == null) {
        this.props.showNotice('Sinun sijaintia ei ole paikannettu', 'Warning');
        return;
      }

      const road = this.state.editingSite.get('road_number');
      const startPart = this.state.editingSite.get('start_part');
      const startDistance = this.state.editingSite.get('start_distance');
      const startCoordinates = await getRoadCoordinates(road, startPart, startDistance);
      const startConverted = toWGS84(startCoordinates.y, startCoordinates.x);
      const distanceToStart = calculateDistance(latitude, longitude, startConverted.latitude, startConverted.longitude);

      const endPart = this.state.editingSite.get('end_part');
      const endDistance = this.state.editingSite.get('end_distance');
      const endCoordinates = await getRoadCoordinates(road, endPart, endDistance);
      const endConverted = toWGS84(endCoordinates.y, endCoordinates.x);
      const distanceToEnd = calculateDistance(latitude, longitude, endConverted.latitude, endConverted.longitude);

      let targetLatitude;
      let targetLongitude;

      if (distanceToStart < distanceToEnd) {
        targetLatitude = startConverted.latitude;
        targetLongitude = startConverted.longitude
      }
      else {
        targetLatitude = endConverted.latitude;
        targetLongitude = endConverted.longitude
      }

      let googleURL = 'https://www.google.com/maps/dir/?api=1&origin=' +
        latitude + ',' + longitude +
        '&destination=' + targetLatitude + ',' + targetLongitude;
      window.open(googleURL, '_blank', 'location=yes');
    }, (error) => console.log(error), { enableHighAccuracy: true });
  }

  render () {
    if (!this.props.show) {
      return null;
    }

    if (this.state.loading) {
      return <div className='loader' />
    }

    return (
      <div>
        <h4 className='filter'>Näytä vain:</h4>
        <label className='filter'>
          Tutka status
          <select onChange={this.changeState.bind(this, 'radarStatusFilter', 'string', null)}
            value={this.state.radarStatusFilter || ''}>
            {
              STATUSES.map(status => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))
            }
          </select>
        </label>
        <label className='filter'>
          PTM status
          <select onChange={this.changeState.bind(this, 'ptmStatusFilter', 'string', null)}
            value={this.state.ptmStatusFilter || ''}>
            {
              STATUSES.map(status => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))
            }
          </select>
        </label>
        <br />
        {!this.props.orderer ?
          <div>
            <button className='button-primary'
              onClick={this.toggleNewSite}>
              Uusi Kohde
            </button>
            <button onClick={this.toggleInputCSVFile}>
              Uusi Kohde CSV-tiedostosta
            </button>
          </div>
          :
          null
        }
        {this.state.sites.size !== 0 ?
          <div className="row">
            <div className="column long">
              <b>Urakka</b>
            </div>
            <div className="column short">
              <b>Koodi</b>
            </div>
            <div className="column long">
              <b>Nimi</b>
            </div>
            <div className="column short">
              <b>Aloitus</b>
            </div>
            <div className="column short">
              <b>Lopetus</b>
            </div>
            <div className="column short">
              <b>Tutka status</b>
            </div>
            <div className="column short">
              <b>PTM status</b>
            </div>
            {!this.props.orderer ?
              <div className="column short">
              </div>
              :
              null
            }
          </div>
          :
          <h3>Ei yhtään kohdetta</h3>
        }
        {
          this.state.sites.map(site => (
            <div key={site.get('id')} className="report"
              onClick={this.showSite.bind(this, site)}>
              <div className="row">
                <div className="column long">
                  {site.get('contract').get('name') || '-'}
                </div>
                <div className="column short">
                  {site.get('custom_id') || '-'}
                </div>
                <div className="column long">
                  {site.get('name')}
                </div>
                <div className="column short">
                  {site.get('start_part') ?
                    (site.get('road_number') + '/' + site.get('start_part') + '/' + site.get('start_distance'))
                    :
                    '-'
                  }
                </div>
                <div className="column short">
                  {site.get('end_part') ?
                    (site.get('road_number') + '/' + site.get('end_part') + '/' + site.get('end_distance'))
                    :
                    '-'
                  }
                </div>
                <div className="column short">
                  {site.get('radar_status') || '-'}
                </div>
                <div className="column short">
                  {site.get('ptm_status') || '-'}
                </div>
                {!this.props.orderer ?
                  <div className="column short">
                    <button className='button-outline' onClick={(e) => {
                      e.stopPropagation();
                      this.aboutToDeleteSite(site);
                    }}>
                      Poista
                    </button>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          ))
        }
        <NewSite show={this.state.creatingNewSite}
          onChange={this.changeState}
          customId={this.state.customId}
          name={this.state.name}
          road={this.state.road}
          startPart={this.state.startPart}
          endPart={this.state.endPart}
          startDistance={this.state.startDistance}
          endDistance={this.state.endDistance}
          length={this.state.length}
          lane={this.state.lane}
          kvl={this.state.kvl}
          massType={this.state.massType}
          massTypes={this.state.massTypes}
          flatness={this.state.flatness}
          operationType={this.state.operationType}
          quality={this.state.quality}
          radarStatus={this.state.radarStatus}
          ptmStatus={this.state.ptmStatus}
          comments={this.state.comments}
          createNewSite={this.createNewSite}
          toggle={this.toggleNewSite}
          loading={this.state.loadingInput} />
        <EditSite editingSite={this.state.editingSite}
          onChange={this.changeState}
          customId={this.state.customId}
          name={this.state.name}
          road={this.state.road}
          startPart={this.state.startPart}
          endPart={this.state.endPart}
          startDistance={this.state.startDistance}
          endDistance={this.state.endDistance}
          length={this.state.length}
          lane={this.state.lane}
          kvl={this.state.kvl}
          massType={this.state.massType}
          massTypes={this.state.massTypes}
          flatness={this.state.flatness}
          operationType={this.state.operationType}
          quality={this.state.quality}
          radarStatus={this.state.radarStatus}
          ptmStatus={this.state.ptmStatus}
          comments={this.state.comments}
          editSite={this.editSite}
          clearEditSite={this.clearEditSite}
          loading={this.state.loadingInput}
          openGoogleMapDirections={this.openGoogleMapDirections} />
        <InputCSVFile show={this.state.inputingCSVFile}
          toggle={this.toggleInputCSVFile}
          onChange={this.changeState}
          loading={this.state.loadingInput}
          input={this.createSiteFromCSVFile} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
}), {
  clearReports, addReports, removeReport, addReport, changeReport,
  showNotice, showConfirm, addContructionSite, removeContructionSite,
  setContructionSites, changeContructionSite, showMessage
})(ConstructionSites);
